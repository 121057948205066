import _isEmpty from 'lodash/isEmpty';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _map from 'lodash/map';
import Actions from 'rapidfab/actions';
import { Form, FormGroup, FormLabel, FormControl, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getLocationFilter, getSubLocationFilter, getSubLocations, getLocationsByRole } from 'rapidfab/selectors';
import { LOCATION_FILTER_DEFAULT_VALUES, SUB_LOCATION_FILTER_DEFAULT_VALUES } from 'rapidfab/constants';

const SubLocations = memo(({
  intl,
}) => {
  const locationFilter = useSelector(getLocationFilter);
  const subLocationFilter = useSelector(getSubLocationFilter);
  const subLocations = useSelector(getSubLocations);
  const locations = useSelector(getLocationsByRole);
  const filteredSubLocations = subLocations.filter(subLocation => subLocation.location === locationFilter);

  const dispatch = useDispatch();

  // If there are no locations to be shown, return null
  if (_isEmpty(locations)) return null;

  const handleSubLocationStateChange = subLocation => {
    dispatch(Actions.LocationFilter.setSubLocation(subLocation));
  };

  const defaultValue =
    subLocationFilter === null
      ? SUB_LOCATION_FILTER_DEFAULT_VALUES.ALL
      : subLocationFilter;

  return (
    <Form>
      <FormGroup as={Row} controlId="subLocation">
        <FormLabel column style={{ flex: 0 }} className="text-nowrap">
          <FormattedMessage id="field.subLocation" defaultMessage="Sub-Location" />
        </FormLabel>{' '}
        <FormControl
          className="truncate-data"
          onChange={event => {
            const value =
              event.target.value === LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED
                ? null
                : event.target.value;
            handleSubLocationStateChange(value);
          }}
          defaultValue={defaultValue}
          value={defaultValue}
          as="select"
          disabled={!locationFilter || !filteredSubLocations.length}
        >
          <option
            key="all"
            value={SUB_LOCATION_FILTER_DEFAULT_VALUES.ALL}
          >
            {intl.formatMessage({
              id: 'all',
              defaultMessage: 'All',
            })}
          </option>
          {_map(filteredSubLocations, subLocation => (
            <option key={subLocation.uri} value={subLocation.uri}>
              {subLocation.name}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    </Form>
  );
});

SubLocations.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};
export default injectIntl(SubLocations);
