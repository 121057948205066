import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import {
  API_RESOURCES,
  MIME_TYPES,
  MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP,
  PRINTER_CONFIGURATION_JSON,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';
import Alert from 'rapidfab/utils/alert';
import { ANATOMICAL_MODEL_PART_MODAL_CONTAINER } from 'rapidfab/constants/forms';
import { AnatomicalModelPartModal } from 'rapidfab/components/AnatomicalModel';
import _find from 'lodash/find';
import { getPrinterTechnology } from 'rapidfab/utils/printerConfiguration';

function getInitialPrinterAnatomyFields(tech, initial) {
  const propertyItem = initial.printer_anatomy_properties?.[0];

  const family = tech?.anatomy?.family.find(fam => fam.name === initial.printer_anatomy_family);

  const element = family?.element?.find(element_ => element_.name === initial.printer_anatomy_element);

  const propertyName = element?.property?.find(property => property.name === propertyItem.name);

  const propertyValue = propertyName?.value?.find(property => property.name === propertyItem.value);

  return {
    printer_anatomy_family: family?.name,
    printer_anatomy_element: element?.name,
    printer_anatomy_property_name: propertyName?.name,
    printer_anatomy_property_value: propertyValue?.name,
  };
}

const getInitialFlexibility = (flexibilities, initial) => {
  if (!initial.flexibility || !flexibilities.includes(`${initial.flexibility}`)) {
    return flexibilities[0];
  }
  return initial.flexibility;
};

const AnatomicalModelPartModalContainer = props => {
  const { uuid, assemblyUri } = props;

  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].get.fetching);
  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].put.fetching);

  const assembly = useSelector(
    state => Selectors.getUUIDResource(state, assemblyUri && extractUuid(assemblyUri)),
  );

  const printerTechName = assembly && MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP[assembly.printing_tech];
  const printerConfig = printerTechName
        && _find(PRINTER_CONFIGURATION_JSON.printerTechnology, { name: printerTechName });

  const printerTech = getPrinterTechnology(assembly.printing_tech);

  let initialColor = null;
  let initialColorOpacity = 1;
  if (printerTech) {
    if (printerTech.rgb) {
      initialColor = '#000000';
    } else if (printerTech.anatomy === null && printerTech.color[0]) {
      const { red, green, blue, alpha } = hexRgb(printerTech.color[0]);
      initialColor = `#${rgbHex(red, green, blue)}`;
      initialColorOpacity = alpha;
    }
  }
  const part = useSelector(state => Selectors.getUUIDResource(state, uuid));
  // Default opacity should be 1 (opaque)
  let initialValues = part || { color_opacity: initialColorOpacity, to_be_printed: true, color: initialColor };
  initialValues = {
    ...initialValues,
    // Clear printer anatomy fields if they are not valid
    ...getInitialPrinterAnatomyFields(printerTech, initialValues),
    // Clear flexibility field if it's not valid
    flexibility: getInitialFlexibility(printerTech?.flexibility || [], initialValues),
  };
  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ANATOMICAL_MODEL_PART_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const selected = {
    printerConfig,
    printerTechName: assembly.printing_tech,
    part,
    isLoading,
    isSubmitting,
    initialFormValues,
  };

  const [file, setFile] = useState(null);

  const onFileChange = event => {
    const newFile = event.target.files[0];
    setFile(newFile);
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line no-shadow
  const onInitialize = uuid => {
    if (uuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].get(uuid));
    }
  };

  // eslint-disable-next-line no-shadow
  const onSave = async (payload, file) => {
    const updatedPayload = { ...payload };
    updatedPayload.solid_infill = true;
    updatedPayload.hollow_shells = null;

    if (updatedPayload.flexibility) {
      updatedPayload.flexibility = Number.parseInt(updatedPayload.flexibility, 10);
    } else {
      updatedPayload.flexibility = 0;
    }

    Object
      .keys(updatedPayload)
      .filter(key => ANATOMICAL_MODEL_PART_MODAL_CONTAINER.FLOAT_FIELDS.includes(key))
      .forEach(key => {
        if (updatedPayload[key]) {
          updatedPayload[key] = Number.parseFloat(updatedPayload[key]);
        }
      });

    // Annoyingly, the backend requires some fields and accepts "none"
    // as an alias for empty. In order for the HTML form to correctly
    // mark fields as required, on initialisation we reset fields == "none" as
    // undefined, and then on submit we set them back to "none"
    if (!updatedPayload.printer_anatomy_family) {
      updatedPayload.printer_anatomy_family = 'none';
    }
    if (!updatedPayload.printer_anatomy_element) {
      updatedPayload.printer_anatomy_element = 'none';
    }
    if (!updatedPayload.printer_anatomy_property_name) {
      updatedPayload.printer_anatomy_property_name = 'none';
    } else if (updatedPayload.printer_anatomy_property_name === ' ') {
      // See printer configuration file, Gel Support
      updatedPayload.printer_anatomy_property_name = '';
    }

    if (!updatedPayload.printer_anatomy_property_value) {
      updatedPayload.printer_anatomy_property_value = 'none';
    } else if (updatedPayload.printer_anatomy_property_value === ' ') {
      // See printer configuration file, Gel Support
      updatedPayload.printer_anatomy_property_value = '';
    }

    updatedPayload.printer_anatomy_properties = [{
      name: updatedPayload.printer_anatomy_property_name,
      value: updatedPayload.printer_anatomy_property_value,
    }];
    delete updatedPayload.printer_anatomy_property_name;
    delete updatedPayload.printer_anatomy_property_value;

    let partRequest;
    let partUri;
    if (updatedPayload.uuid) {
      partUri = updatedPayload.uri;
      partRequest = dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART]
        .put(updatedPayload.uuid, updatedPayload))
        .then(response => {
          Alert.success('Part updated successfully');
          return response;
        });
    } else {
      if (!file) {
        Alert.warning('Please upload a file first.');
        return;
      }
      updatedPayload.anatomical_model_assembly = props.assemblyUri;
      partRequest = dispatch(Actions.Api.nautilus[API_RESOURCES.ANATOMICAL_MODEL_PART].post(updatedPayload))
        .then(prepTaskResponse => {
          partUri = prepTaskResponse.headers.location;
          Alert.success('Part created successfully');
          return prepTaskResponse;
        });
    }

    partRequest.then(async response => {
      const { uploadLocation } = response.headers;
      if (file && uploadLocation) {
        await dispatch(Actions.UploadModel.upload(uploadLocation, file, MIME_TYPES.STL));
      }

      props.onClose(partUri);
    });
  };

  useEffect(() => onInitialize(uuid), [uuid]);

  return (
    <AnatomicalModelPartModal
      {...props}
      {...selected}
      file={file}
      onFileChange={onFileChange}
      onSave={payload => onSave(payload, file)}
    />
  );
};

AnatomicalModelPartModalContainer.propTypes = {
  uuid: PropTypes.string,
  assemblyUri: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
AnatomicalModelPartModalContainer.defaultProps = {
  uuid: null,
};
export default AnatomicalModelPartModalContainer;
