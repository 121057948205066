// This is a temporary file
// which contains constants that will removed once functionality is not needed

export const ANATOMICAL_MODEL_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const PART_STATUSES = {
  NOT_UPLOADED: 'not-uploaded',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  ERROR: 'error',
};

// Keys are copied from `MANUFACTURING_PROCESSES` constant
// to be able to map values in the UI for the 3rd part config
export const MANUFACTURING_PROCESS_TO_PRINTER_CONFIGURATION_MAP = {
  SLA: 'SLA',
  SLS: 'SLS',
  FDM: 'FDM',
  Polyjet: 'Polyjet',
  'Polyjet Anatomy': 'Polyjet - Anatomy',
};

// This Json is provided via 3rd party
// Leaving all metadata to be able to replace values when needed
// Copy-paste json content into this constant, when there is a new version
export const PRINTER_CONFIGURATION_JSON = {
  // **NOTE**: If this value is changed, you need to update the Anatomical ZIP file utility in Nautilus
  // Specifically the `printerConfigVersion` key in the `package.json` file of the generated zip file
  // Can be found in `store_package_json()` in `nautilus/backend/anatomical_model/generate_build_file.py

  version: 7,
  date: '2025-02-18',
  language: 'en',
  printerTechnology: [
    {
      name: 'Polyjet',
      description: 'High resolution, multi-material printer',
      rgb: true,
      transparency: true,
      defaultThickness: '2.0',
      flexibility: ['30', '40', '50', '60', '70', '85', '95', '100'],
      color: [],
      finish: ['Standard', 'Sanding', 'Clear Coat', 'Polishing'],
      hollowShells: false,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'Polyjet - Anatomy',
      description: 'Anatomical simulation printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: [],
      finish: ['Standard'],
      hollowShells: false,
      hollowDefault: false,
      anatomy: {
        name: 'J-Series Digital Anatomy Printer',
        description: 'DAP Software Settings',
        family: [
          {
            name: 'Blood Vessel',
            element: [
              {
                name: 'Clot',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Fibrotic',
                        default: true,
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Highly Fribrotic',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                      {
                        name: 'Soft',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Fixtures',
                visible: false,
                property: [
                  {
                    name: 'Rigidity',
                    value: [
                      {
                        name: 'Rigidity 1; Shore A:40',
                        default: true,
                        color: ['84638DFF', 'FBFE80FF'],
                      },
                      {
                        name: 'Rigidity 2; Shore A:50',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Rigidity 3; Shore A:60',
                        color: ['80467FFF', 'F9FE94FF'],
                      },
                      {
                        name: 'Rigidity 4; Shore A:70',
                        color: ['82427DFF', 'F9FE94FF'],
                      },
                      {
                        name: 'Rigidity 5; Shore A:85',
                        color: ['863476FF', 'FCFFA8FF'],
                      },
                      {
                        name: 'Rigidity 6; High Dimensional Stability',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Frame',
                visible: false,
                property: [
                  {
                    name: 'Rigidity',
                    value: [
                      {
                        name: 'Rigidity 1; Shore A:30',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Rigidity 2; Shore A:40',
                        default: true,
                        color: ['84638DFF', 'FBFE80FF'],
                      },
                      {
                        name: 'Rigidity 3; Shore A:50',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Rigidity 4; Shore A:60',
                        color: ['80467FFF', 'F9FE94FF'],
                      },
                      {
                        name: 'Rigidity 5; Shore A:70',
                        color: ['82427DFF', 'F9FE94FF'],
                      },
                      {
                        name: 'Rigidity 6; Shore A:85',
                        color: ['863476FF', 'FCFFA8FF'],
                      },
                      {
                        name: 'Rigidity 7; High Dimensional Stability',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Gel Support',
                visible: false,
                property: [
                  {
                    name: 'Mix',
                    value: [
                      {
                        name: 'Pure Gel Support',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Gel Support',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Inlets',
                visible: true,
                property: [
                  {
                    name: 'Rigidity',
                    value: [
                      {
                        name: 'Rigidity 1; Shore A:70',
                        default: true,
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Rigidity 2; Shore A:85',
                        color: ['863476FF', 'FCFFA8FF'],
                      },
                      {
                        name: 'Rigidity 3; Shore A:95',
                        color: ['8B2B72FF', 'FDFFB2FF'],
                      },
                      {
                        name: 'Rigidity 4; High Dimensional Stability',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Reinforcements',
                visible: false,
                property: [
                  {
                    name: 'Rigidity',
                    value: [
                      {
                        name: 'Rigidity 1; Shore A:70',
                        default: true,
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Rigidity 2; Shore A:95',
                        color: ['8B2B72FF', 'FDFFB2FF'],
                      },
                      {
                        name: 'Rigidity 3; High Dimensional Stability',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Solid Tumor',
                visible: true,
                property: [
                  {
                    name: 'Type',
                    value: [
                      {
                        name: 'A: Soft non-encapsulated tumor',
                        default: true,
                        color: ['EE4F1AFF'],
                      },
                      {
                        name: 'B: Soft encapsulated tumor',
                        color: ['C03A0EFF'],
                      },
                      {
                        name: 'C: Stiff non-encapsulated tumor',
                        color: ['F0F0F0FF'],
                      },
                      {
                        name: 'D: Soft uniform tumor',
                        color: ['FFFF64FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Suturable Vessel Wall',
                visible: true,
                property: [
                  {
                    name: 'Suture Strength',
                    value: [
                      {
                        name: 'Medium Strength Vessel',
                        default: true,
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'High Strength Vessel',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Valve Annulus',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft',
                        default: true,
                        color: ['E6D2CAFF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['EDE0DAFF'],
                      },
                      {
                        name: 'Stiffened',
                        color: ['DADADAFF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Valve Leaflet',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Moderately Stiff',
                        default: true,
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Stiffened',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Stiff',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Vascular Calcification',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft Calcium Deposits',
                        default: true,
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Moderately Stiff Calcium Deposits',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Stiff Calcium Deposits',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Vessel Wall',
                visible: true,
                property: [
                  {
                    name: 'Compliance',
                    value: [
                      {
                        name: 'Compliant',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Moderately Compliant',
                        color: ['84638DFF', 'FBFE80FF'],
                      },
                      {
                        name: 'Slightly Compliant',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Low Compliant',
                        color: ['80467FFF', 'F9FE94FF'],
                      },
                      {
                        name: 'Semi-Rigid',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Rigid',
                        color: ['863476FF', 'FCFFA8FF'],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'General Anatomy',
            element: [
              {
                name: 'Dense Connective Tissues',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft',
                        default: true,
                        color: ['80467FFF', 'F9FE94FF'],
                      },
                      {
                        name: 'Rigid',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Highly Rigid',
                        color: ['863476FF', 'FCFFA8FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Gel Support',
                visible: false,
                property: [
                  {
                    name: '',
                    value: [
                      {
                        name: '',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Hollow Internal Organs',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Compliant',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Moderately Compliant',
                        color: ['84638DFF', 'FBFE80FF'],
                      },
                      {
                        name: 'Slightly Compliant',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Low Compliant',
                        color: ['80467FFF', 'F9FE94FF'],
                      },
                      {
                        name: 'Semi-Rigid',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Rigid',
                        color: ['863476FF', 'FCFFA8FF'],
                      },
                      {
                        name: 'Highly-Rigid',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Liver',
                visible: true,
                property: [
                  {
                    name: 'Type',
                    value: [
                      {
                        name: 'Highly Contractile',
                        default: true,
                        color: ['F0E6B4FF'],
                      },
                      {
                        name: 'Thicker Coated Highly Contractile',
                        color: ['F0E6A0FF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['E6DC5AFF'],
                      },
                      {
                        name: 'Thicker Coated Moderately Stiff',
                        color: ['E6DC46FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Solid Internal Organs',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Fiber Contraction 1',
                        default: true,
                        color: ['FC8D8DFF'],
                      },
                      {
                        name: 'Fiber Contraction 2',
                        color: ['FA5F5FFF'],
                      },
                      {
                        name: 'Fiber Contraction 3',
                        color: ['F81D1DFF'],
                      },
                      {
                        name: 'Fiber Contraction 4',
                        color: ['D20606FF'],
                      },
                      {
                        name: 'Fiber Contraction 5',
                        color: ['A30505FF'],
                      },
                      {
                        name: 'Fiber Contraction 6',
                        color: ['646464FF'],
                      },
                      {
                        name: 'Fiber Contraction 7',
                        color: ['646464FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Solid Tumor',
                visible: true,
                property: [
                  {
                    name: 'Type',
                    value: [
                      {
                        name: 'A: Soft non-encapsulated tumor',
                        default: true,
                        color: ['EE4F1AFF'],
                      },
                      {
                        name: 'B: Soft encapsulated tumor',
                        color: ['C03A0EFF'],
                      },
                      {
                        name: 'C: Stiff non-encapsulated tumor',
                        color: ['F0F0F0FF'],
                      },
                      {
                        name: 'D: Soft uniform tumor',
                        color: ['FFFF64FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Subcutaneous Fat',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Non-encapsulated highly contractile',
                        default: true,
                        color: ['FFFFA5FF'],
                      },
                      {
                        name: 'Soft coated highly contractile',
                        color: ['FFFFD8FF'],
                      },
                      {
                        name: 'Stiff coated highly contractile',
                        color: ['FFFAA5FF'],
                      },
                      {
                        name: 'Non-encapsulated contractile',
                        color: ['FFFF91FF'],
                      },
                      {
                        name: 'Soft coated contractile',
                        color: ['FFFFCCFF'],
                      },
                      {
                        name: 'Stiff coated contractile',
                        color: ['FFFA91FF'],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'Musculoskeletal',
            element: [
              {
                name: 'Facet Joints',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft',
                        default: true,
                        color: ['FC8D8DFF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['FA5F5FFF'],
                      },
                      {
                        name: 'Stiff',
                        color: ['A30505FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'General Bone',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Porous',
                        default: true,
                        color: ['E4DDCBFF'],
                      },
                      {
                        name: 'Slightly Dense',
                        color: ['EDE9DEFF'],
                      },
                      {
                        name: 'Dense',
                        color: ['F0ECE3FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Intervertebral Discs',
                visible: true,
                property: [
                  {
                    name: 'Type',
                    value: [
                      {
                        name: 'Normal',
                        default: true,
                        color: ['FDF5B7FF'],
                      },
                      {
                        name: 'Degenerated',
                        color: ['C39438FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Ligament',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['84638DFF', 'FBFE80FF'],
                      },
                      {
                        name: 'Stiff',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Long Bone',
                visible: true,
                property: [
                  {
                    name: 'Type',
                    value: [
                      {
                        name: 'Miniature - Phalanges, Metatarsal, etc.',
                        default: true,
                        color: ['F9F9EEFF'],
                      },
                      {
                        name: 'Medium - Ulna, Radius, etc.',
                        default: true,
                        color: ['F9F9EEFF'],
                      },
                      {
                        name: 'Typical - Femur, Tibia, etc.',
                        color: ['EAE7D7FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Nerves',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft',
                        default: true,
                        color: ['FC8D8DFF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['FA5F5FFF'],
                      },
                      {
                        name: 'Stiff',
                        color: ['A30505FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Open End',
                visible: true,
                property: [
                  {
                    name: 'Anatomy Preset Materials',
                    value: [
                      {
                        name: 'Low Transparent Open End',
                        default: true,
                        color: ['ffff64ff'],
                      },
                      {
                        name: 'Medium Transparent Open End',
                        color: ['bbe0e3FF'],
                      },
                      {
                        name: 'Stiff',
                        color: ['FFFFFFFF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Ribs',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Moderately Stiff',
                        default: true,
                        color: ['FEFBE7FF'],
                      },
                      {
                        name: 'Very Stiff',
                        color: ['FFF5D7FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Skull',
                visible: true,
                property: [
                  {
                    name: 'Density',
                    value: [
                      {
                        name: 'Dense',
                        default: true,
                        color: ['EBE8DCFF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Vertabra',
                visible: true,
                property: [
                  {
                    name: 'Porosity',
                    value: [
                      {
                        name: 'Porous',
                        default: true,
                        color: ['E0DCC9FF'],
                      },
                      {
                        name: 'Dense',
                        color: ['EBE8DCFF'],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'Structural Heart',
            element: [
              {
                name: 'Clot',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft',
                        default: true,
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Fibrotic',
                        default: true,
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Highly Fribrotic',
                        default: true,
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Frame',
                visible: false,
                property: [
                  {
                    name: 'Rigidity Level',
                    value: [
                      {
                        name: 'Rigidity 1; ShoreA:30',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Rigidity 2; ShoreA:40',
                        color: ['84638DFF', 'FBFE80FF'],
                      },
                      {
                        name: 'Rigidity 3; ShoreA:50',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Rigidity 4; ShoreA:70',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Rigidity 5; ShoreA:85',
                        color: ['863476FF', 'FCFFA8FF'],
                      },
                      {
                        name: 'Rigidity 6; ShoreA:95',
                        color: ['8B2B72FF', 'FDFFB2FF'],
                      },
                      {
                        name: 'Rigidity 7; High Dimensional Stability',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Gel Support',
                visible: false,
                property: [
                  {
                    name: '',
                    value: [
                      {
                        name: '',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Myocardium',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Highly Contractile',
                        default: true,
                        color: ['FC8D8DFF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['FA5F5FFF'],
                      },
                      {
                        name: 'Stiffened',
                        color: ['F81D1DFF'],
                      },
                      {
                        name: 'Very Stiff',
                        color: ['D20606FF'],
                      },
                      {
                        name: 'Extremely Stiff',
                        color: ['A30505FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Reinforcement',
                visible: false,
                property: [
                  {
                    name: 'Rigidity Level',
                    value: [
                      {
                        name: 'Rigidity 1; Shore A:50',
                        default: true,
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Rigidity 2; Shore A:70',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Rigidity 3; High Dimensional Stability',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Solid Tumor',
                visible: true,
                property: [
                  {
                    name: 'Type',
                    value: [
                      {
                        name: 'A: Soft non-encapsulated tumor',
                        default: true,
                        color: ['EE4F1AFF'],
                      },
                      {
                        name: 'B: Soft encapsulated tumor',
                        color: ['C03A0EFF'],
                      },
                      {
                        name: 'C: Stiff non-encapsulated tumor',
                        color: ['F0F0F0FF'],
                      },
                      {
                        name: 'D: Soft uniform tumor',
                        color: ['FFFF64FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Valve Annulus',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft Healthy',
                        default: true,
                        color: ['E6D2CAFF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['EDE0DAFF'],
                      },
                      {
                        name: 'Stiffened Diseased',
                        color: ['DADADAFF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Valve Cordae',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Highly Extensible',
                        default: true,
                        color: ['FC8D8DFF'],
                      },
                      {
                        name: 'Extensible',
                        color: ['A30505FF'],
                      },
                      {
                        name: 'Stiffened',
                        color: ['FFFF64FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Valve Leaflet',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft Healthy',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        default: true,
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Stiffened',
                        default: true,
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Extensively Stiff',
                        default: true,
                        color: ['8B2B72FF', 'FDFFB2FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Valvular Calcification',
                visible: true,
                property: [
                  {
                    name: 'Stiffness',
                    value: [
                      {
                        name: 'Soft',
                        default: true,
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Moderately Stiff',
                        color: ['82427DFF', 'FAFF9EFF'],
                      },
                      {
                        name: 'Stiff',
                        color: ['A62162FF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Vessel Wall',
                visible: true,
                property: [
                  {
                    name: 'Compliance',
                    value: [
                      {
                        name: 'Compliant',
                        default: true,
                        color: ['FFFF64FF'],
                      },
                      {
                        name: 'Slightly Compliant',
                        color: ['815184FF', 'F9FE8AFF'],
                      },
                      {
                        name: 'Low Compliant',
                        color: ['80467FFF', 'F9FE94FF'],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'Radiology',
            element: [
              {
                name: 'High (RadioMatrix)',
                visible: true,
                property: [
                  {
                    name: 'Percentage',
                    value: [
                      {
                        name: '100 - RadioMatrix',
                        default: true,
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '99 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '98 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '97 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '96 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '95 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '94 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '93 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '92 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '91 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '90 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '89 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '88 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '87 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '86 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '85 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '84 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '83 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '82 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '81 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '80 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '79 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '78 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '77 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '76 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '75 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '74 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '73 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '72 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '71 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '70 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '69 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '68 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '67 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '66 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '65 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '64 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '63 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '62 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '61 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '60 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '59 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '58 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '57 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '56 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '55 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '54 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '53 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '52 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '51 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '50 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '49 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '48 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '47 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '46 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '45 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '44 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '43 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '42 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '41 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '40 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '39 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '38 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '37 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '36 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '35 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '34 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '33 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '32 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '31 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '30 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '29 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '28 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '27 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '26 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '25 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '24 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '23 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '22 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '21 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '20 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '19 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '18 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '17 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '16 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '15 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '14 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '13 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '12 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '11 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '10 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '9 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '8 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '7 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '6 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '5 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '4 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '3 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '2 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '1 - RadioMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Low (TissueMatrix)',
                visible: true,
                property: [
                  {
                    name: 'Percentage',
                    value: [
                      {
                        name: '95 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '94 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '93 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '92 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '91 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '90 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '89 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '88 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '87 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '86 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '85 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '84 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '83 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '82 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '81 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '80 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '79 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '78 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '77 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '76 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '75 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '74 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '73 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '72 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '71 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '70 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '69 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '68 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '67 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '66 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '65 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '64 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '63 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '62 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '61 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '60 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '59 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '58 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '57 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '56 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '55 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '54 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '53 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '52 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '51 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '50 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '49 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '48 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '47 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '46 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '45 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '44 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '43 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '42 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '41 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '40 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '39 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '38 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '37 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '36 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '35 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '34 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '33 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '32 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '31 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '30 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '29 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '28 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '27 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '26 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '25 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '24 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '23 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '22 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '21 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '20 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '19 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '18 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '17 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '16 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '15 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '14 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '13 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '12 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '11 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '10 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '9 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '8 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '7 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '6 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '5 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '4 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '3 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '2 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '1 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                      {
                        name: '0 - TissueMatrix',
                        color: ['FFFFFFFF', 'F0F0F0FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Custom',
                visible: true,
                property: [
                  {
                    name: 'Custom',
                    value: [
                      {
                        name: 'Custom',
                        default: true,
                        color: ['000000FF'],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Base Materials',
                visible: true,
                property: [
                  {
                    name: 'Resin Type',
                    value: [
                      {
                        name: 'Rigid Clear',
                        default: true,
                        color: ['F0F0F0FF'],
                      },
                      {
                        name: 'Rigid Cyan',
                        color: ['00FFFFFF'],
                      },
                      {
                        name: 'Rigid Yellow',
                        color: ['FFFF00FF'],
                      },
                      {
                        name: 'Rigid Magenta',
                        color: ['FF00FFFF'],
                      },
                      {
                        name: 'Rigid White',
                        color: ['FFFFFFFF'],
                      },
                      {
                        name: 'Rigid Black',
                        color: ['000000FF'],
                      },
                      {
                        name: 'Agilus Clear',
                        color: ['F0F0F0FF'],
                      },
                      {
                        name: 'Agilus Cyan',
                        color: ['00FFFFFF'],
                      },
                      {
                        name: 'Agilus Yellow',
                        color: ['FFFF00FF'],
                      },
                      {
                        name: 'Agilus Magenta',
                        color: ['FF00FFFF'],
                      },
                      {
                        name: 'Agilus White',
                        color: ['FFFFFFFF'],
                      },
                      {
                        name: 'Agilus Black',
                        color: ['000000FF'],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'FDM',
      description: 'Low resolution, Filament printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: ['FFFFFFFF', 'ffffffff'],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'DLP',
      description: 'Medium resolution, high speed resin printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: ['EEE1C6FF', 'eee1c6ff'],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'SLA',
      description: 'Medium resolution, standard speed resin printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: ['FFFFFFFF', 'ffffffff'],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
    {
      name: 'SLS',
      description: 'Medium resolution, standard speed powder printer',
      rgb: false,
      transparency: false,
      defaultThickness: '2.0',
      flexibility: ['100'],
      color: ['555555FF', '555555ff'],
      finish: ['Standard'],
      hollowShells: true,
      hollowDefault: false,
      anatomy: null,
    },
  ],
};

export const ELEMENT_WITHOUT_PROPERTIES_NAME = 'Gel Support';
