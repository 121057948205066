import _mapKeys from 'lodash/mapKeys';
import _camelCase from 'lodash/camelCase';

const parseCookieValues = string => {
  if (!string) {
    return {};
  }
  const cookies = string
    .split(';')
    .map(v => v.split('='))
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((accumulator, v) => {
      accumulator[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return accumulator;
    }, {});
  return _mapKeys(cookies, (value, key) => _camelCase(key));
};

export default parseCookieValues;
