import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  Col,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  OverlayTrigger,
  Row,
  Tooltip,
  Form as BSForm,
} from 'react-bootstrap';
import { finalFormInputTypes } from 'rapidfab/types';
import FormRow from 'rapidfab/components/FormRow';
import ColorPicker from 'rapidfab/ColorPicker';
import {
  PART_STATUSES,
} from 'rapidfab/constants';
import _upperFirst from 'lodash/upperFirst';
import { FormControlSelect } from 'rapidfab/components/formTools';
import FileInput from 'rapidfab/components/records/order/edit/FileInput';
import _startCase from 'lodash/startCase';
import { Form, Field } from 'react-final-form';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwatchColorPicker from 'rapidfab/SwatchColorPicker';
import { getPrinterOptions, getPrinterTechnology } from 'rapidfab/utils/printerConfiguration';
import _uniq from 'lodash/uniq';

const AnatomicalModelPartModal = ({
  onClose,
  isLoading,
  isSubmitting,
  part,
  onSave,
  file,
  onFileChange,
  initialFormValues,
  printerTechName,
}) => {
  const disabledActions = isLoading || isSubmitting;
  const downloadLocation = part && part.download_location;
  const partStatus = part && part.status;
  const printerTech = getPrinterTechnology(printerTechName);

  return (
    <Modal size="lg" show onHide={onClose} backdrop="static" contentClassName="modal-assembly-part">
      <Form
        onSubmit={onSave}
        initialValues={initialFormValues}
        validate={values => {
          const errors = {};
          const re = /["*/:<>?\\|]/g;
          if (values.name) {
            const matches = [...values.name.matchAll(re)].flatMap(match => match);
            if (matches.length) {
              errors.name = `Invalid character(s): ${_uniq(matches).join(', ')}`;
            }
          }
          return errors;
        }}
        render={({ handleSubmit, values, form, errors, valid }) => {
          const printerOptions = getPrinterOptions(printerTech, values);

          // Some elements have no properties, which is represented in the config by a single
          // property with name and value === "", which we've set to " " (see printer config)
          // Ricoh wanted the field to be disabled and display 'None'
          const noPropertyOptions = printerOptions.propertyNames?.length === 1 && printerOptions.propertyNames[0].name === '';

          const onResetField = event => {
            // If a printer anatomy field is changed, we need to reset it's children
            // E.g if a family changes, clear all other fields.
            const fieldName = event.target.name;

            switch (fieldName) {
              case 'printer_anatomy_family':
                form.reset({
                  ...values,
                  printer_anatomy_element: '',
                  printer_anatomy_property_name: '',
                  printer_anatomy_property_value: '',
                });
                break;
              case 'printer_anatomy_element':
                form.reset({
                  ...values,
                  printer_anatomy_property_name: '',
                  printer_anatomy_property_value: '',
                });
                break;
              case 'printer_anatomy_property_name':
                form.reset({
                  ...values,
                  printer_anatomy_property_value: '',
                });
                break;
              default: break;
            }
          };

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const colors = printerOptions?.colors;
            if (!colors || colors.length === 0) return;
            const split = colors.map(rgba => {
              // Color is RGBA Hex, so must split
              const color = `#${rgba.slice(0, 6)}`;
              const opacity = Number.parseInt(rgba.slice(6), 16) / 255;
              return { color, opacity };
            });
            const currentColor = values.color?.toLowerCase();
            const currentOpacity = values.color_opacity;
            // If it's in the list, don't reset it
            if (split.some(
              ({ color, opacity }) => color.toLowerCase() === currentColor
                  && opacity === currentOpacity,
            )) return;
            form.reset({
              ...values,
              color: split[0].color,
              color_opacity: split[0].opacity,
            });
          }, [JSON.stringify(printerOptions.colors)]);

          return (
            <form onSubmit={handleSubmit}>
              <ModalHeader closeButton>
                <ModalTitle>
                  {initialFormValues?.uuid ? 'Edit' : 'Create'} Part
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                {
                  isLoading
                    ? <Loading />
                    : (
                      <Row>
                        <Col xs={6}>
                          <FormRow
                            id="field.name"
                            defaultMessage="Name"
                            isRequired
                          >
                            <Field
                              name="name"
                              render={props => (
                                <FormControl
                                  required
                                  {...props.input}
                                />
                              )}
                            />
                            {errors.name && <div className="my-1 text-danger">{errors.name}</div>}
                          </FormRow>
                          <FormRow>
                            <Field
                              type="checkbox"
                              name="to_be_printed"
                              render={props => (
                                <BSForm.Check
                                  {...props.input}
                                  label="Print This Object"
                                />
                              )}
                            />
                          </FormRow>
                          <FormRow
                            id="field.finish"
                            defaultMessage="Finish"
                            isRequired
                          >
                            <Field
                              name="finish"
                              render={props => (
                                <FormControlSelect
                                  id="finish"
                                  {...props.input}
                                  disabled={!printerTech?.finish?.length}
                                >
                                  {!printerTech?.finish?.length && (
                                    <option disabled value="">Not Available</option>
                                  )}
                                  {printerTech?.finish?.map(finishOption => (
                                    <option value={finishOption} key={finishOption}>
                                      {finishOption}
                                    </option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>

                          <FormRow
                            defaultMessage="Flexibility (Shore Value)"
                            isRequired={printerTech?.flexibility.length > 0}
                          >
                            <Field
                              name="flexibility"
                              type="number"
                              render={props => (
                                <FormControlSelect
                                  {...props.input}
                                  required={printerTech?.flexibility.length > 0}
                                  disabled={!printerTech?.flexibility.length}
                                >
                                  {!printerTech?.flexibility.length && (
                                    <option disabled value="">Not Available</option>
                                  )}
                                  {printerTech?.flexibility.map(flexOption => (
                                    <option value={flexOption} key={flexOption}>
                                      {flexOption}
                                    </option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>

                          {printerOptions.rgb && (
                            <FormRow
                              defaultMessage="Transparency"
                            >
                              <Field
                                name="color_opacity"
                                type="range"
                                render={props => (
                                  <FormControl
                                    {...props.input}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-delay="0"
                                    // Ricoh's definition of opacity is 10x ours (10 = opaque)
                                    title={Math.floor(props.input.value * 10)}
                                    step="0.1"
                                    min="0"
                                    max="1"
                                  />
                                )}
                              />
                              <div className="d-flex justify-content-between">
                                <i>transparent</i>
                                <i>opaque</i>
                              </div>
                            </FormRow>
                          )}

                          <FormRow
                            id="field.color"
                            defaultMessage="Color"
                          >
                            <Field
                              name="color"
                              render={color_props => {
                                const color_field = { ...color_props.input, ...color_props.meta };
                                return printerOptions.rgb ? (
                                  <ColorPicker colorField={color_field} />
                                ) : (
                                  <Field
                                    name="color_opacity"
                                    render={props => {
                                      const opacity_field = { ...props.input, ...props.meta };
                                      return (
                                        <SwatchColorPicker
                                          colors={printerOptions.colors}
                                          colorField={color_field}
                                          opacityField={opacity_field}
                                        />
                                      );
                                    }}
                                  />

                                );
                              }}
                            />

                          </FormRow>

                        </Col>
                        <Col xs={6}>
                          <FormRow
                            defaultMessage="Printer Anatomy Family"
                            isRequired
                          >
                            <Field
                              name="printer_anatomy_family"
                              render={props => (
                                <FormControlSelect
                                  id="printerAnatomyFamily"
                                  {...props.input}
                                  onChange={event => {
                                    onResetField(event);
                                    props.input.onChange(event);
                                  }}
                                  disabled={!printerOptions.families?.length}
                                  required
                                >
                                  <option value="" disabled>
                                    {printerOptions.families ? 'Choose...' : 'Not Available'}
                                  </option>
                                  {printerOptions.families?.map(type => (
                                    <option value={type.name} key={type.name}>{_startCase(type.name)}</option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Printer Anatomy Element"
                            isRequired
                          >
                            <Field
                              name="printer_anatomy_element"
                              render={props => (
                                <FormControlSelect
                                  id="printerAnatomyElement"
                                  {...props.input}
                                  onChange={event => {
                                    onResetField(event);
                                    props.input.onChange(event);
                                  }}
                                  disabled={!printerOptions.elements?.length}
                                  required
                                >
                                  <option value="" disabled>
                                    {printerOptions.elements ? 'Choose...' : 'Not Available'}
                                  </option>
                                  {printerOptions.elements?.map(element => (
                                    <option value={element.name} key={element.name}>{_startCase(element.name)}</option>
                                  ))}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>
                          <FormRow
                            defaultMessage="Printer Anatomy Property Name"
                            isRequired
                          >
                            <Field
                              name="printer_anatomy_property_name"
                              render={props => (
                                <FormControlSelect
                                  id="printerAnatomyPropertyName"
                                  {...props.input}
                                  onChange={event => {
                                    onResetField(event);
                                    props.input.onChange(event);
                                  }}
                                  disabled={!printerOptions.propertyNames?.length || noPropertyOptions}
                                  required={!noPropertyOptions}
                                >
                                  {noPropertyOptions ? (
                                    <option value="" disabled>None</option>
                                  ) : (
                                    <>
                                      <option value="" disabled>
                                        {printerOptions.propertyNames ? 'Choose...' : 'Not Available'}
                                      </option>
                                      {printerOptions.propertyNames?.map(type => (
                                        <option value={type.name} key={type.name}>{_upperFirst(type.name)}</option>
                                      ))}
                                    </>
                                  )}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>

                          <FormRow
                            defaultMessage="Printer Anatomy Property Value"
                            isRequired
                          >
                            <Field
                              name="printer_anatomy_property_value"
                              render={props => (
                                <FormControlSelect
                                  id="printerAnatomyPropertyValue"
                                  {...props.input}
                                  onChange={event => {
                                    onResetField(event);
                                    props.input.onChange(event);
                                  }}
                                  disabled={!printerOptions.propertyValues?.length || noPropertyOptions}
                                  required={!noPropertyOptions}
                                >
                                  {noPropertyOptions ? (
                                    <option value="" disabled>None</option>
                                  ) : (
                                    <>
                                      <option value="" disabled>
                                        {printerOptions.propertyValues ? 'Choose...' : 'Not Available'}
                                      </option>
                                      {printerOptions.propertyValues?.map(type => (
                                        <option value={type.name} key={type.name}>{_upperFirst(type.name)}</option>
                                      ))}
                                    </>
                                  )}
                                </FormControlSelect>
                              )}
                            />
                          </FormRow>
                          {downloadLocation && (
                            <FormRow defaultMessage="Uploaded Part">
                              {partStatus === PART_STATUSES.PROCESSING && <Loading inline />}
                              {partStatus === PART_STATUSES.ERROR && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={(
                                    <Tooltip>
                                      {part.notes || 'Failed to process STL'}
                                    </Tooltip>
                                  )}
                                >
                                  <FontAwesomeIcon icon={faExclamationCircle} />
                                </OverlayTrigger>
                              )}
                              {partStatus === PART_STATUSES.PROCESSED && <a href={downloadLocation}>{part.file_name || 'Download'}</a>}
                            </FormRow>
                          )}
                          <FormRow
                            defaultMessage={downloadLocation ? 'New File Upload' : 'File Upload'}
                            isRequired={!downloadLocation}
                          >
                            <FileInput
                              fileType={FileInput.fileTypes.model}
                              handleFileChange={onFileChange}
                              name={file && file.name}
                              required={!downloadLocation}
                              onlyFileInput
                            />
                          </FormRow>
                        </Col>
                      </Row>
                    )
                }
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  variant="success"
                  disabled={disabledActions || !valid}
                >
                  { isSubmitting ? <Loading /> : 'Save' }
                </Button>
                <Button variant="primary" disabled={isSubmitting} onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          );
        }}
      />
    </Modal>
  );
};

AnatomicalModelPartModal.defaultProps = {
  isSubmitting: false,
  isLoading: false,
  file: null,
  part: null,
};

AnatomicalModelPartModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  initialFormValues: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    finish: PropTypes.string.isRequired,
    to_be_printed: PropTypes.bool.isRequired,
    hollow_shells: PropTypes.bool.isRequired,
    solid_infill: PropTypes.bool.isRequired,
    flexibility: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    color_opacity: PropTypes.number.isRequired,
    printer_anatomy_family: PropTypes.string.isRequired,
    printer_anatomy_element: PropTypes.string.isRequired,
    printer_anatomy_property_name: PropTypes.string.isRequired,
    printer_anatomy_property_value: PropTypes.string.isRequired,
  }).isRequired,
  printerTechName: PropTypes.string.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
  part: PropTypes.shape({
    file_name: PropTypes.string,
    download_location: PropTypes.string,
    status: PropTypes.string,
    notes: PropTypes.string,
  }),
  onFileChange: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  meta: PropTypes.shape({}).isRequired,
  input: finalFormInputTypes.isRequired,
};

export default AnatomicalModelPartModal;
