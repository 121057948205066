import parseCookieValues from 'rapidfab/utils/cookie';
import { useEffect, useRef } from 'react';

const useAutoLogout = (isAutoLogoutEnabled, handleLogout) => {
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    const scheduleAutoLogout = () => {
      // Skip if auto logout feature is not enabled
      if (!isAutoLogoutEnabled) {
        return;
      }

      // Get the logout time from the cookie
      const { autologoutAfter: logoutDateTime } = parseCookieValues(document.cookie);

      if (!logoutDateTime) {
        return;
      }

      // Get the logout time in milliseconds
      const logoutTimestamp = new Date(logoutDateTime).getTime();
      // Get the time before logout by subtracting the current time from the logout time
      const timeBeforeLogout = logoutTimestamp - Date.now();

      // If the time before logout is less than or equal to 0, logout the user
      if (timeBeforeLogout <= 0) {
        // Logout the user
        handleLogout();
      } else {
        // Otherwise, set the timeout to logout the user
        timeoutIdRef.current = setTimeout(() => {
          handleLogout();
        }, timeBeforeLogout);
      }
    };

    scheduleAutoLogout();
    // Cleanup on unmount
    return () => clearTimeout(timeoutIdRef.current);
  }, [isAutoLogoutEnabled, handleLogout]);
};

export default useAutoLogout;
