import { createRunStartProgress } from 'rapidfab/actions/createRunProgress';
import { useCreateRunProgressContext } from 'rapidfab/context/CreateRunProgressContext';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _chunk from 'lodash/chunk';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import {
  API_RESOURCES, BUILD_PLATE_CHOICES,
  FEATURES, LINE_ITEM_COMPOSITION_TYPES,
  LIST_BY_URIS_CHUNK_SIZE,
  LOCATION_FILTER_DEFAULT_VALUES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  WORKFLOW_TYPES,
} from 'rapidfab/constants';
import RunNew from 'rapidfab/components/records/run/RunNew';
import Actions from 'rapidfab/actions';
import {
  getAvailableWorkflowsOfType,
  getBureauUri,
  getIsDebugModeEnabled,
  getLabelRelationshipsForPieceUris,
  getLabels,
  getLineItemsForRunNew,
  getLocationFilter,
  getLocations,
  getMaterials,
  getModelLibrariesByUri,
  getModelsByUri,
  getPiecesForRunNew,
  getPrintersForRunNew,
  getSpecimens,
  isFeatureEnabled,
  getOrdersByUri,
  getAssemblyPartMeta,
  getAssemblyMeta,
} from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { loadModelLibrariesWithModels } from 'rapidfab/dispatchers/modelLibrary';
import Alert from 'rapidfab/utils/alert';
import { FormattedMessage } from 'react-intl';
import {
  getLineItemWorkflowTypeObjectKey,
  transformLineItemsAvailableForRunPrints,
} from 'rapidfab/utils/lineItemUtils';
import _difference from 'lodash/difference';
import { loadExportControlData } from 'rapidfab/dispatchers/labelRelationship';
import _filter from 'lodash/filter';
import _find from 'lodash/find';

const RunNewContainer = props => {
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  const bureau = useSelector(getBureauUri);
  let printers = useSelector(getPrintersForRunNew);
  const modelsByUri = useSelector(getModelsByUri);
  const lineItems = useSelector(getLineItemsForRunNew);
  let pieces = useSelector(getPiecesForRunNew);
  const labels = useSelector(getLabels);
  const isGeneralMFGLanguageEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));

  const { setCreateRunUri, setRunCreationPayload } = useCreateRunProgressContext();

  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MODELER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching);

  const fetchingPieceList = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINT].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PIECE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MODEL].list.fetching);

  const isPrinterFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINTER].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching);

  const orders = useSelector(getOrdersByUri);
  const creatingRun = useSelector(state => state.ui.nautilus[API_RESOURCES.CREATE_RUNS].post.fetching);

  const printListStore = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINT].list);
  const lineItemListStore = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list);
  const locations = useSelector(getLocations);

  const locationFilter = useSelector(getLocationFilter);
  const [materialFilter, setMaterialFilter] = useState([]);
  const [selectedBuildPlateModel, setSelectedBuildPlateModel] = useState(BUILD_PLATE_CHOICES.LINE_ITEM);

  const currentResourceUris = selectedBuildPlateModel === BUILD_PLATE_CHOICES.LINE_ITEM
    ? transformLineItemsAvailableForRunPrints(lineItems)
    : _map(pieces, 'uri');

  const labelRelationships = useSelector(state =>
    getLabelRelationshipsForPieceUris(state, currentResourceUris));

  // Handle location filter.
  if (locationFilter) {
    printers = printers.filter(printer =>
      printer.location === locationFilter,
    );

    pieces = pieces.filter(piece =>
      piece.location === locationFilter,
    );
  }

  // Handle material filter.
  if (!_isEmpty(materialFilter)) {
    // Uris of selected materials.
    const materialFilterUris = _map(materialFilter, 'uri');

    printers = printers.filter(printer =>
      materialFilterUris
        .some(materialUri => printer.printer_type.materials.includes(materialUri)),
    );
  } else {
    printers = printers.filter(p => p);
  }

  const selectedLocations = locationFilter === null
    ? [{ uri: LOCATION_FILTER_DEFAULT_VALUES.UNASSIGNED, name: 'Unassigned' }]
    : locations.filter(location => location.uri === locationFilter);

  const materials = useSelector(getMaterials);
  const specimens = useSelector(getSpecimens);
  const modelLibrariesByUri = useSelector(getModelLibrariesByUri);
  const workflows = useSelector(state => getAvailableWorkflowsOfType(state, WORKFLOW_TYPES.ADDITIVE_MANUFACTURING));
  const isSpecimenLibraryFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.SPECIMEN_LIBRARY));

  /* Line items that contain pieces only */
  const filteredLineItems = lineItems
    .filter(lineItem => !_isEmpty(lineItem.pieces));
  const assemblyMetas = useSelector(state => getAssemblyMeta(state, filteredLineItems));
  const assemblyPartMetas = useSelector(state => getAssemblyPartMeta(state, filteredLineItems));

  const isPowderWorkflowFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW));

  const selected = {
    bureau,
    fetching,
    fetchingPieceList,
    lineItems,
    modelsByUri,
    modelLibrariesByUri,
    printers,
    pieces,
    printListStore,
    lineItemListStore,
    specimens,
    locations,
    selectedLocations,
    locationFilter,
    materials,
    workflows,
    creatingRun,
    orders,
    assemblyMetas,
    assemblyPartMetas,
    isSpecimenLibraryFeatureEnabled,
    isDebugModeEnabled,
    isPrinterFetching,
    labelRelationships,
    isGeneralMFGLanguageEnabled,
    selectedBuildPlateModel,
  };

  const dispatch = useDispatch();

  const loadModelsInChunks = currentLineItems => {
    const models = currentLineItems.map(lineItem => {
      const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
      const {
        models_for_co_print: modelUrisForCoPrint = [],
      } = lineItem;

      const isCoPrintLineItem = lineItem.composition_type === LINE_ITEM_COMPOSITION_TYPES.CO_PRINT;
      if (isCoPrintLineItem) {
        return modelUrisForCoPrint[0];
      }
      return lineItem[workflowTypeKey]?.model;
    }).map(model => (model?.uri || model)).filter(Boolean);

    _chunk(models, LIST_BY_URIS_CHUNK_SIZE).forEach(modelsChunk => {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list(
        { uri: modelsChunk },
        {},
        {},
        {},
        true, // Force update
      ));
    });
  };

  const onInitialize = currentBureau => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.ASSEMBLY_META].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.ASSEMBLY_PART_META].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list({}, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.SPECIMEN].list()).then(specimenResponse => {
      if (isSpecimenLibraryFeatureEnabled && !isPowderWorkflowFeatureEnabled) {
        const modelLibraryURIs = _uniq(_map(specimenResponse.json.resources, 'model_library'));
        _chunk(modelLibraryURIs, LIST_BY_URIS_CHUNK_SIZE).forEach(modelLibraryURIChunk => {
          loadModelLibrariesWithModels(dispatch, { uri: modelLibraryURIChunk });
        });
      }
    });
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    if (!isPowderWorkflowFeatureEnabled) {
      loadModelLibrariesWithModels(dispatch);
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list());

    // line-item-with-available-for-run-prints used for finding specimen
    // associated with pieces
    dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].clear('list'));
  };

  const onSave = payload => {
    if (isSpecimenLibraryFeatureEnabled) {
      // If, somehow, a user sets a specimen in a 'create runs' (right side component / top grid)
      // they should get an error
      const activePieces = pieces.filter(piece_ => payload.pieces.includes(piece_.uri));
      const hasInRunSpecimen = activePieces.some(piece => piece.type === 'specimen');
      if (hasInRunSpecimen) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.modelSpecimen.cannotBeAddedToProductionRun"
            defaultMessage="Model Specimens cannot be added to production runs"
          />);
        return;
      }
    }

    setRunCreationPayload(payload);

    dispatch(Actions.Api.nautilus[API_RESOURCES.CREATE_RUNS].post(payload)).then(createRunResponse => {
      const createRunUri = createRunResponse.headers.location;
      setCreateRunUri(createRunUri);
      // Add new run creation to the queue of the run progress tracking
      dispatch(createRunStartProgress(extractUuid(createRunUri), createRunUri));
    });
  };

  const loadDataItems = (
    selectedBuildPlateModel,
    filters,
    pageParams,
    searchParams,
    queryParams,
    currentLocationFilter,
  ) => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.PRINT].clear('list'),
    );

    if (selectedBuildPlateModel === 'piece') {
      const searchData = { name: searchParams };
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.PRINT].list(
          locationFilter === '' ? { ...filters } : { ...filters, location: extractUuid(locationFilter) },
          pageParams,
          searchData,
          queryParams,
          true, // Force update
        ),
      ).then(
        response => {
          const prints = response.json.resources;
          if (!_isEmpty(prints)) {
            const pieceUris = _uniq(prints.map(print => print.piece));
            const lineItemUris = _uniq(prints.map(print => print.line_item));

            if (!_isEmpty(pieceUris)) {
              loadExportControlData(dispatch, pieceUris);
            }

            dispatch(
              Actions.Api.nautilus[API_RESOURCES.PIECE].list(
                {
                  uri: pieceUris,
                  current_step_position: 1,
                  ...currentLocationFilter && { location: currentLocationFilter },
                },
                { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                {},
                {},
                true,
              ),
            );

            dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list(
              {
                // There are different URIs, but backend is working only
                // by uuids
                uri: lineItemUris,
              },
              { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
            ),
            ).then(lineItemsResponse => {
              const currentLineItems = lineItemsResponse.json.resources;
              loadModelsInChunks(currentLineItems);
            });
          }
        },
      );
    } else {
      dispatch(
        Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].clear('list'),
      );
      const updatedFilters = { ...filters };
      if (locationFilter === '') {
        delete updatedFilters.location;
      } else if (!locationFilter) {
        updatedFilters.location = null;
      }
      const searchData = { order_name: searchParams };

      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WITH_AVAILABLE_FOR_RUN_PRINTS].list(
        updatedFilters,
        pageParams,
        searchData,
        queryParams,
      )).then(
        response => {
          const currentLineItems = response.json.resources;
          const lineItemUris = transformLineItemsAvailableForRunPrints(currentLineItems);

          if (!_isEmpty(lineItemUris)) {
            loadExportControlData(dispatch, lineItemUris);
          }

          _chunk(currentLineItems, LIST_BY_URIS_CHUNK_SIZE).forEach(lineItemChunk => {
            const lineItemURIs = lineItemChunk.map(
              lineItem => lineItem.uri,
            );

            dispatch(
              Actions.Api.nautilus[API_RESOURCES.PRINT].list(
                {
                  line_item: lineItemURIs,
                  process_step_position: 1,
                },
                { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                {},
                {},
                true,
              ),
            ).then(printsResponse => {
              const pieceUris = _uniq(_map(printsResponse.json.resources, 'piece'));
              _chunk(pieceUris, LIST_BY_URIS_CHUNK_SIZE).forEach(pieceUrisUriChunk =>
                dispatch(
                  Actions.Api.nautilus[API_RESOURCES.PIECE].list(
                    {
                      uri: pieceUrisUriChunk,
                      current_step_position: 1,
                      ...currentLocationFilter && { location: currentLocationFilter },
                    },
                    { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                    {},
                    {},
                    true,
                  ),
                ));
            });
            loadModelsInChunks(lineItemChunk);
          });
        },
      );
    }
  };
  const handleOnChange = (filterType, value) => {
    if (filterType === 'location') {
      dispatch(Actions.LocationFilter.setLocation(value));
    } else if (filterType === 'material') {
      setMaterialFilter(value);
    }
  };

  const checkServiceProviderJobs = async selectedPieces => {
    const workflowUris = _uniq(_map(selectedPieces, 'workflow'));
    if (!workflowUris.length) return [];
    const processStepsResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list(
        { workflows: workflowUris },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        true,
      ),
    );
    const processSteps = processStepsResponse?.json?.resources || [];
    const serviceProcessSteps = processSteps.filter(ps => ps.is_service);
    const withServiceWorkflowUris = [];
    serviceProcessSteps.forEach(ps => {
      ps.workflows.forEach(workflowUri => {
        if (workflowUris.includes(workflowUri) && !withServiceWorkflowUris.includes(workflowUri)) {
          withServiceWorkflowUris.push(workflowUri);
        }
      });
    });
    const piecesWithServiceWorkflow = selectedPieces.filter(piece => withServiceWorkflowUris.includes(piece.workflow));
    const withServiceLineItemUris = _uniq(_map(piecesWithServiceWorkflow, 'line_item'));
    if (!withServiceLineItemUris.length) {
      return [];
    }
    const serviceProviderJobsResponse = await dispatch(
      Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER_JOB].list(
        { line_item: withServiceLineItemUris },
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
        {},
        {},
        true,
      ),
    );
    const serviceProviderJobs = serviceProviderJobsResponse?.json?.resources || [];
    if (serviceProviderJobs.length !== withServiceLineItemUris.length) {
      return _difference(withServiceLineItemUris, serviceProviderJobs.map(job => job.line_item));
    }
    return [];
  };

  const getLabelRelationshipsForPieces = resourceUris => {
    const labelRelationshipResources = _filter(labelRelationships, labelRelationshipItem =>
      resourceUris.includes(labelRelationshipItem.target_uri));

    return labelRelationshipResources.map(labelRelationshipItem => ({
      ...labelRelationshipItem,
      label: _find(labels, { uri: labelRelationshipItem.label }),
    }))
      .filter(({ label }) => label?.name !== 'No Export Control');
  };

  const dispatched = {
    onInitialize,
    onSave,
    loadDataItems,
    handleOnChange,
    loadModelsInChunks,
    checkServiceProviderJobs,
    getLabelRelationshipsForPieces,
    setSelectedBuildPlateModel,
  };

  useEffect(() => onInitialize(bureau, props.uuid), []);

  return (
    <RunNew
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

RunNewContainer.defaultProps = {
  uuid: null,
};

RunNewContainer.propTypes = {
  bureau: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  uuid: PropTypes.string,
};

export default RunNewContainer;
